// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-explain-explain-js": () => import("./../../../src/pages/explain/explain.js" /* webpackChunkName: "component---src-pages-explain-explain-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-m-piano-adult-js": () => import("./../../../src/pages/m/piano/adult.js" /* webpackChunkName: "component---src-pages-m-piano-adult-js" */),
  "component---src-pages-m-piano-child-js": () => import("./../../../src/pages/m/piano/child.js" /* webpackChunkName: "component---src-pages-m-piano-child-js" */),
  "component---src-pages-m-piano-wxpage-js": () => import("./../../../src/pages/m/piano/wxpage.js" /* webpackChunkName: "component---src-pages-m-piano-wxpage-js" */),
  "component---src-pages-m-voice-adult-js": () => import("./../../../src/pages/m/voice/adult.js" /* webpackChunkName: "component---src-pages-m-voice-adult-js" */),
  "component---src-pages-m-voice-wxpage-js": () => import("./../../../src/pages/m/voice/wxpage.js" /* webpackChunkName: "component---src-pages-m-voice-wxpage-js" */),
  "component---src-pages-piano-adult-js": () => import("./../../../src/pages/piano/adult.js" /* webpackChunkName: "component---src-pages-piano-adult-js" */),
  "component---src-pages-piano-child-js": () => import("./../../../src/pages/piano/child.js" /* webpackChunkName: "component---src-pages-piano-child-js" */),
  "component---src-pages-voice-adult-js": () => import("./../../../src/pages/voice/adult.js" /* webpackChunkName: "component---src-pages-voice-adult-js" */)
}

