import React from 'react';
import { Helmet } from 'react-helmet';

import 'styles/app.less';

export default ({ pageContext, children }) => {
  const helmet = (
    <Helmet>
      <title>Finger指教音乐中心</title>
      <meta
        name="description"
        content="【简介】
Finger指教音乐中心是Finger旗下的校区品牌。提供趣味少儿钢琴启蒙、少儿古典吉他、零基础成人钢琴、零基础成人声乐等音乐课程。
Finger是国内音乐教育品牌，致力于为中国家庭提供专业音乐教育服务。2018年牵手绿城教育，打造Finger指教音乐线下校区，用追求品质的态度为少儿用户、成人用户提供钢琴、声乐、古典吉他等音乐教学。


【课程】
少儿钢琴课程（3到16周岁适用）
Finger指教音乐中心使用《克里蒙奇钢琴教程》作为少儿钢琴课专用教材。
《克里蒙奇钢琴教程》是一套由美国、加拿大、中国3国专家共同研发的少儿钢琴教程。教程使用“3A教学法”，从趣味（Amuse）、理解（Aware）、运用（Apply）3个方面出发，综合培养孩子的钢琴演奏、乐理知识运用与音乐创作能力。

成人钢琴课程（16周岁以上适用）
Finger成人钢琴课从基础的弹奏技巧和乐理开始，一直到即兴伴奏和演奏织体复杂的乐曲。
课程涵盖了经典、民歌、电影音乐、流行以及爵士等多种不同音乐风格的曲目，向学员全方位展现音乐的独特魅力。

少儿古典吉他课程（6到12周岁适用）
Finger指教音乐中心使用《FingerDoes古典吉他教程》作为少儿古典吉他课专用教材。课程设计源自美国启蒙教学法，秉承”兴趣教学“的授课理念，同时配合小学阶段孩子的身心发展，从乐理、视奏、听力、曲目和技巧五个方面开发学员的音乐综合素养

成人声乐课程（16周岁以上适用）
Finger成人零基础声乐课内容涵盖了流行、摇滚、R&B等多种不同音乐风格的曲目，向学员全方位展现音乐的独特魅力。针对成人的学习习惯，课程中加入了录音棚体验、演唱作品录制、学员成长记录等配套内容，让学员能够清楚地见证自身音色的成长。

【校区地址】
西溪湿地总校：杭州市西湖区文二西路808号西溪壹号23幢
黄龙旗舰校区：杭州市西湖区黄龙世纪广场B座1层23号
中大银泰校区：杭州市下城区万家星城28幢4号商铺（东文路）
城西银泰校区：杭州市拱墅区塘萍路380、382号（申花公园东南门对面）"
      />
      <meta
        name="keywords"
        content="杭州学琴、西湖区学琴、下城区学琴、上城区学琴、江干区学琴、拱墅区学琴、英皇考级、钢琴考级、兴趣、钢琴启蒙、1对1教学、国际教研师资、杭州学琴、西湖区学琴、下城区学琴、上城区学琴、江干区学琴、拱墅区学琴、英皇考级、钢琴考级、兴趣、钢琴启蒙、1对1教学、国际教研师资、买琴、雅马哈、Yamaha、施坦威、Kawai、卡瓦依、珠江、三角钢琴、电子琴、电钢琴、真钢、立式钢琴、手卷钢琴、买钢琴"
      />
      <meta
        name="Copyright"
        content="本页版权归©2019杭州指教艺术培训有限公司所有"
      />
    </Helmet>
  );

  return pageContext.layout !== 'mobile' ? (
    <div className="d-layout">
      {helmet}
      {children}
    </div>
  ) : (
    <div className="m-layout">
      {helmet}
      {children}
    </div>
  );
};
